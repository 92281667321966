import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useRouteLoaderData,
	useRouteError,
	NavLink,
} from "@remix-run/react";
import "./tailwind.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { json, LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import { useChangeLanguage } from "remix-i18next/react";
import { cn } from "./utils";
import { useTranslation } from "react-i18next";
import { i18next } from "./modules/i18next/service.server";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { LocaleProvider } from "./hooks/use-locale";
import CookieConsent from "./components/cookie-consent-banner";

const queryClient = new QueryClient();

export const links: LinksFunction = () => [
	{
		rel: "icon",
		type: "image/x-icon",
		href: "/favicon.ico",
	},
];

export const handle = {
	// In the handle export, we can add a i18n key with namespaces our route
	// will need to load. This key can be a single string or an array of strings.
	// TIP: In most cases, you should set this to your defaultNS from your i18n config
	// or if you did not set one, set it to the i18next default namespace "translation"
	i18n: "common",
};

export async function loader({ request }: LoaderFunctionArgs) {
	const locale = await i18next.getLocale(request);
	return json({ locale });
}

export function Layout({ children }: { children: React.ReactNode }) {
	const data = useRouteLoaderData<typeof loader>("root");
	const locale = data?.locale ? data.locale : "en";

	useChangeLanguage(locale);

	return (
		<LocaleProvider locale={locale}>
			<QueryClientProvider client={queryClient}>
				<InnerLayout locale={locale}>{children}</InnerLayout>
			</QueryClientProvider>
		</LocaleProvider>
	);
}

function App() {
	return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary() {
	const error = useRouteError();
	captureRemixErrorBoundaryError(error);
	return (
		<div className="flex h-full w-full items-center justify-center">
			<div className="flex flex-col items-center">
				<ExclamationTriangleIcon className="mb-2 h-10 w-10" />
				<h1 className="mb-4">Oops! An error occurred.</h1>
				<NavLink
					reloadDocument
					className="rounded-md bg-blue-500 p-2"
					to="/"
				>
					Take me back
				</NavLink>
			</div>
		</div>
	);
}

export function HydrateFallback() {
	return <h1>Loading...</h1>;
}

function InnerLayout({ locale, children }) {
	let { i18n } = useTranslation();
	return (
		<html
			suppressHydrationWarning={true}
			data-theme={"dark"}
			className={cn("dark")}
			lang={locale}
			dir={i18n.dir()}
		>
			<head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1"
				/>
				<Meta />
				<Links />
			</head>
			<body suppressHydrationWarning>
				{children}
                <CookieConsent />
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	);
}
