import { CookieIcon } from "lucide-react";
import { Button } from "./ui/button";
import { useEffect, useState } from "react";
import { cn } from "~/utils/tailwind";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { NavLink } from "@remix-run/react";

type Props = {
	variant?: "default" | "small";
	demo?: boolean;
	onAcceptCallback?: () => void;
	onDeclineCallback?: () => void;
};

export default function CookieConsent({
	variant = "default",
	demo = false,
	onAcceptCallback = () => {},
	onDeclineCallback = () => {},
}: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const [hide, setHide] = useState(false);

	const { t } = useTranslation();

	const consentMutation = useMutation({
		mutationKey: ["cookie-consent"],
		mutationFn: async () => {
			fetch("/action/set-cookie-consent", {
				method: "POST",
			});
		},
	});

	const accept = () => {
		setIsOpen(false);
		consentMutation.mutate();
		setTimeout(() => {
			setHide(true);
		}, 700);
		onAcceptCallback();
	};

	const decline = () => {
		setIsOpen(false);
		setTimeout(() => {
			setHide(true);
		}, 700);
		onDeclineCallback();
	};

	useEffect(() => {
		try {
			setIsOpen(true);
			if (document.cookie.includes("cookieConsent")) {
				if (!demo) {
					setIsOpen(false);
					setTimeout(() => {
						setHide(true);
					}, 700);
				}
			}
		} catch (e) {
			// console.log("Error: ", e);
		}
	}, []);

	return variant != "small" ? (
		<div
			className={cn(
				"fixed bottom-0 left-0 right-0 z-[200] w-full duration-200 sm:bottom-4 sm:left-4 sm:max-w-md",
				!isOpen
					? "translate-y-8 opacity-0 transition-[opacity,transform]"
					: "translate-y-0 opacity-100 transition-[opacity,transform]",
				hide && "hidden"
			)}
		>
			<div className="m-3 rounded-md border border-border bg-background shadow-lg dark:bg-card">
				<div className="grid gap-2">
					<div className="flex h-14 items-center justify-between border-b border-border p-4">
						<h1 className="text-lg font-medium">
							{t("banners.cookie_consent.title")}
						</h1>
						<CookieIcon className="h-[1.2rem] w-[1.2rem]" />
					</div>
					<div className="p-4">
						<p className="text-start text-sm font-normal">
							{t("banners.cookie_consent.description")}
							<br />
							<br />
							<span className="text-xs">
								{t("common.by_clicking")} "
								<span className="font-medium opacity-80">
									{t("common.accept")}
								</span>
								", {t("common.you_agree_to_our_use_of_cookies")}
							</span>
							{/* <br />
							<NavLink
								to="/cookie-policy"
								className="text-xs underline"
							>
								{t("common.learn_more")}
							</NavLink> */}
						</p>
					</div>
					<div className="flex gap-2 border-t border-border p-4 py-5 dark:bg-background/20">
						<Button onClick={accept} className="w-full">
							{t("common.accept")}
						</Button>
						<Button
							onClick={decline}
							className="w-full"
							variant="secondary"
						>
							{t("common.decline")}
						</Button>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div
			className={cn(
				"fixed bottom-0 left-0 right-0 z-[200] w-full duration-200 sm:bottom-4 sm:left-4 sm:max-w-md",
				!isOpen
					? "translate-y-8 opacity-0 transition-[opacity,transform]"
					: "translate-y-0 opacity-100 transition-[opacity,transform]",
				hide && "hidden"
			)}
		>
			<div className="m-3 rounded-lg border border-border bg-background dark:bg-card">
				<div className="flex items-center justify-between p-3">
					<h1 className="text-lg font-medium">
						{t("banners.cookie_consent.title")}
					</h1>
					<CookieIcon className="h-[1.2rem] w-[1.2rem]" />
				</div>
				<div className="-mt-2 p-3">
					<p className="text-left text-sm text-muted-foreground">
						{t("banners.cookie_consent.description")}
					</p>
				</div>
				<div className="mt-2 flex items-center gap-2 border-t p-3">
					<Button
						onClick={accept}
						className="h-9 w-full rounded-full"
					>
						{t("common.accept")}
					</Button>
					<Button
						onClick={decline}
						className="h-9 w-full rounded-full"
						variant="outline"
					>
						{t("common.decline")}
					</Button>
				</div>
			</div>
		</div>
	);
}
